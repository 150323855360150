import React, { useState, useEffect } from 'react';
import { motion, useMotionValue, AnimatePresence } from 'framer-motion';
import { ChevronRight, Menu, X, Phone, Mail, MapPin, ArrowRight, Check, Star, Users, BarChart } from 'lucide-react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';

const placeholderImages = [
  'https://images.unsplash.com/photo-1551836022-d5d88e9218df?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDF8fGRpZ2l0YWwlMjBtYXJrZXRpbmd8fGVufDB8fHx8MTY1NDAwOTg3OQ&ixlib=rb-1.2.1&q=80&w=1200',
  'https://images.unsplash.com/photo-1519389950473-47ba0277781c?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDJ8fGRpZ2l0YWwlMjBtYXJrZXRpbmd8fGVufDB8fHx8MTY1NDAwOTg5MA&ixlib=rb-1.2.1&q=80&w=800',
  'https://images.unsplash.com/photo-1498050108023-c5249f4df085?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwzNjUyOXwwfDF8c2VhcmNofDEwfHxkaWdpdGFsJTIwbWFya2V0aW5nfGVufDB8fHx8MTY1NDAwOTkxMw&ixlib=rb-1.2.1&q=80&w=800',
];


// Custom hook for parallax effect
const useParallax = (value, distance) => {
  return {
    y: value * distance
  }
}

const Navigation = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();

  return (
    <motion.nav
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ type: 'spring', stiffness: 300, damping: 30 }}
      className="bg-gradient-to-r from-purple-500 to-indigo-600 p-4 fixed w-full z-50"
    >
      <div className="container mx-auto flex justify-between items-center">
        <motion.h1
          initial={{ opacity: 0, scale: 0.5 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-3xl font-bold text-white"
        >
          <Link to="/">MarketPro</Link>
        </motion.h1>
        <div className="hidden md:flex space-x-4">
          {['Home', 'About', 'Services', 'Contact'].map((page) => (
            <Link to={page === 'Home' ? '/' : `/${page.toLowerCase()}`} key={page}>
              <motion.button
                className={`text-white hover:text-pink-200 transition-colors duration-300 ${location.pathname === (page === 'Home' ? '/' : `/${page.toLowerCase()}`) ? 'font-bold' : ''
                  }`}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                {page}
              </motion.button>
            </Link>
          ))}
        </div>
        <div className="md:hidden">
          <motion.button onClick={() => setIsOpen(!isOpen)} whileTap={{ scale: 0.95 }}>
            {isOpen ? <X color="white" /> : <Menu color="white" />}
          </motion.button>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
            transition={{ duration: 0.3 }}
            className="md:hidden mt-4"
          >
            {['Home', 'About', 'Services', 'Contact'].map((page) => (
              <Link to={page === 'Home' ? '/' : `/${page.toLowerCase()}`} key={page}>
                <motion.button
                  onClick={() => setIsOpen(false)}
                  className="block w-full text-left py-2 px-4 text-white hover:bg-indigo-700 transition-colors duration-300"
                  whileHover={{ x: 10 }}
                >
                  {page}
                </motion.button>
              </Link>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.nav>
  );
};

const HomePage = () => {
  const y = useParallax(useMotionValue(0), 0.1);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-8 pt-24"
    >
      <motion.h2
        className="text-5xl font-bold text-indigo-600 mb-6"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Elevate Your Brand with MarketPro
      </motion.h2>
      <motion.p
        className="text-xl mb-8 text-gray-700"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        We're not just a marketing firm - we're your strategic partner in navigating the digital landscape. At MarketPro, we blend creativity with data-driven insights to craft marketing solutions that drive real results.
      </motion.p>
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <img src={placeholderImages[0]} alt="Marketing concept" className="w-full rounded-lg shadow-2xl" />
      </motion.div>
      <motion.div
        className="mt-12 grid md:grid-cols-3 gap-8"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.8 }}
      >
        <div className="text-center">
          <Users size={48} className="mx-auto text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Expert Team</h3>
          <p>Seasoned professionals with diverse industry experience</p>
        </div>
        <div className="text-center">
          <BarChart size={48} className="mx-auto text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Data-Driven</h3>
          <p>Strategies backed by robust analytics and insights</p>
        </div>
        <div className="text-center">
          <Star size={48} className="mx-auto text-indigo-600 mb-4" />
          <h3 className="text-xl font-semibold mb-2">Results-Focused</h3>
          <p>Committed to delivering measurable outcomes for your business</p>
        </div>
      </motion.div>
    </motion.div>
  );
};

const AboutPage = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    className="container mx-auto p-8 pt-24"
  >
    <motion.h2
      className="text-5xl font-bold text-indigo-600 mb-6"
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      About MarketPro
    </motion.h2>
    <div className="grid md:grid-cols-2 gap-12">
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <p className="text-xl mb-6 text-gray-700">
          Founded in 2010, MarketPro has been at the forefront of digital marketing innovation for over a decade. Our journey began with a simple mission: to help businesses thrive in the digital age.
        </p>
        <p className="text-xl mb-6 text-gray-700">
          Today, we're proud to be a leading marketing firm, serving clients across various industries - from tech startups to established Fortune 500 companies. Our success is built on three core principles:
        </p>
        <ul className="space-y-4 mb-6">
          <li className="flex items-center">
            <Check className="text-green-500 mr-2" />
            <span>Innovation: Staying ahead of digital trends</span>
          </li>
          <li className="flex items-center">
            <Check className="text-green-500 mr-2" />
            <span>Collaboration: Working closely with our clients</span>
          </li>
          <li className="flex items-center">
            <Check className="text-green-500 mr-2" />
            <span>Results: Delivering measurable impact</span>
          </li>
        </ul>
        <p className="text-xl text-gray-700">
          Our team of experts brings together diverse skills and experiences, ensuring we can tackle any marketing challenge. From SEO specialists to creative designers, data analysts to content strategists - we have the talent to elevate your brand.
        </p>
      </motion.div>
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
      >
        <img src={placeholderImages[1]} alt="Our team" className="w-full rounded-lg shadow-2xl mb-8" />
        <blockquote className="text-xl italic text-gray-700">
          "MarketPro doesn't just deliver campaigns; they deliver growth. Their strategic approach has been instrumental in our success."
          <footer className="text-right mt-2">- Jane Doe, CEO of TechInnovate</footer>
        </blockquote>
      </motion.div>
    </div>
  </motion.div>
);

const ServicesPage = () => {
  const services = [
    {
      name: 'Digital Marketing',
      description: 'Comprehensive online strategies to boost your brand presence.',
      details: [
        'Search Engine Optimization (SEO)',
        'Pay-Per-Click Advertising (PPC)',
        'Email Marketing Campaigns',
        'Content Marketing Strategy'
      ]
    },
    {
      name: 'Brand Development',
      description: 'Create a unique and memorable brand identity that resonates with your audience.',
      details: [
        'Brand Strategy & Positioning',
        'Visual Identity Design',
        'Brand Guidelines Creation',
        'Brand Voice Development'
      ]
    },
    {
      name: 'Social Media Management',
      description: 'Engage your audience and grow your following across all major platforms.',
      details: [
        'Platform-Specific Strategy',
        'Content Creation & Curation',
        'Community Management',
        'Social Media Advertising'
      ]
    },
    {
      name: 'Content Creation',
      description: 'Compelling content that tells your story and drives conversions.',
      details: [
        'Blog Posts & Articles',
        'Whitepapers & eBooks',
        'Video Production',
        'Infographic Design'
      ]
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="container mx-auto p-8 pt-24"
    >
      <motion.h2
        className="text-5xl font-bold text-indigo-600 mb-12"
        initial={{ y: 50, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        Our Services
      </motion.h2>
      <div className="grid md:grid-cols-2 gap-8">
        {services.map((service, index) => (
          <motion.div
            key={service.name}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: index * 0.1 + 0.4 }}
            className="bg-white p-8 rounded-lg shadow-lg hover:shadow-2xl transition-shadow duration-300"
          >
            <h3 className="text-2xl font-semibold text-indigo-600 mb-4">{service.name}</h3>
            <p className="text-gray-700 mb-4">{service.description}</p>
            <ul className="space-y-2">
              {service.details.map((detail, idx) => (
                <li key={idx} className="flex items-center">
                  <ArrowRight className="text-indigo-600 mr-2" size={16} />
                  <span>{detail}</span>
                </li>
              ))}
            </ul>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

const ContactPage = () => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.5 }}
    className="container mx-auto p-8 pt-24"
  >
    <motion.h2
      className="text-5xl font-bold text-indigo-600 mb-12"
      initial={{ y: 50, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      Get in Touch
    </motion.h2>
    <div className="grid md:grid-cols-2 gap-12">
      <motion.div
        initial={{ x: -50, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <p className="text-xl mb-8 text-gray-700">
          Ready to take your marketing to the next level? We're here to help! Whether you have a specific project in mind or just want to explore how we can boost your brand, don't hesitate to reach out.
        </p>
        <div className="space-y-6 mb-8">
          <motion.div className="flex items-center" whileHover={{ x: 10 }}>
            <Phone className="mr-4 text-indigo-600" size={24} />
            <span className="text-lg">+1 (555) 123-4567</span>
          </motion.div>
          <motion.div className="flex items-center" whileHover={{ x: 10 }}>
            <Mail className="mr-4 text-indigo-600" size={24} />
            <span className="text-lg">info@marketpro.com</span>
          </motion.div>
          <motion.div className="flex items-center" whileHover={{ x: 10 }}>
            <MapPin className="mr-4 text-indigo-600" size={24} />
            <span className="text-lg">123 Marketing St, Business City, 12345</span>
          </motion.div>
        </div>
        <p className="text-xl text-gray-700">
          Our team is available Monday through Friday, 9am to 6pm EST. We strive to respond to all inquiries within 24 hours.
        </p>
      </motion.div>
      <motion.div
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.6 }}
        className="space-y-4"
      >
        <img src={placeholderImages[2]} alt="Contact us" className="w-full rounded-lg shadow-2xl mb-8" />
        <h3 className="text-2xl font-semibold text-indigo-600">What happens next?</h3>
        <ol className="list-decimal list-inside space-y-2">
          <li>We'll schedule a free consultation to discuss your needs</li>
          <li>Our team will analyze your current marketing efforts</li>
          <li>We'll present a customized strategy tailored to your goals</li>
          <li>Once approved, we'll kickstart your marketing transformation!</li>
        </ol>
      </motion.div>
    </div>
  </motion.div>
);


const App = () => {
  return (
    <Router>
      <div className="min-h-screen bg-gradient-to-br from-pink-100 to-indigo-100 text-gray-800">
        <Navigation />
        <AnimatePresence mode="wait">
          <motion.main
            key="mainsadjas"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
            transition={{ duration: 0.3 }}
            className="py-8"
          >
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/about" element={<AboutPage />} />
              <Route path="/services" element={<ServicesPage />} />
              <Route path="/contact" element={<ContactPage />} />
            </Routes>
          </motion.main>
        </AnimatePresence>
        <footer className="bg-indigo-600 py-6 text-center text-white">
          <p>&copy; 2024 MarketPro. All rights reserved.</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;